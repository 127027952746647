import {ActionReducerMap} from "@ngrx/store";
import * as auth from '../auth/auth.reducer';
import * as project from './project/project.reducer';
import * as notification from '../notification/notification.reducer';
import * as moduleManagment from "./settings/module-managment/module.reducer";
import * as integration from './integrations/integration.reducer';

export interface AppState {
	auth: auth.AuthState
	project: project.ProjectState,
	notification: notification.NotificationState,
	moduleManagment: moduleManagment.ModuleState
	integration: integration.IntegrationQRState
}

export const appReducers: ActionReducerMap<AppState> = {
	auth: auth.authReducer,
	project: project.projectReducer,
	notification: notification.notificationReducer,
	moduleManagment: moduleManagment.moduleReducer,
	integration: integration.integrationReducer
}
