import { Flag } from 'src/app/users/models/user.model';
import { Business } from '../../auth/models/login.model';

export interface ClientList extends Business {
	selected: boolean;
}

export interface DropdownItem {
	id: number;
	name: string;
}

export interface ProjectData {
	projectName: string;
	projectId: number;
	logoProject: string;
	backgroundUrl: string;
	color: string;
}

export interface ProjectDataForm {
	nameProyect: string;
	razonSocialProyect: string;
	rfcProyect: string;
	taxRegimeProyect: number;
	zipCodeProyect: number;
	nameContact: string;
	firstName: string;
	secondName: string;
	email: string;
	phoneNumber: number | string;
	client: number;
	fee: number;
	lada: Flag | null | string;
}

export interface ProjectInfo {
	valid: boolean;
	data: ProjectDataForm;
	index: number;
}

export interface ProjectDataFormExcel extends ProjectDataForm {
	errors?: string[];
	index?: number;
	typeError?: string;
}

export enum TypesLoadProject {
	ONE,
	MASSIVE,
}

export interface ProjectInfoResponse {
	projectCompany: ProjectCompany;
	projectBusinessUnit: ProjectBusinessUnit;
	projectContactsCompany: ProjectContactsCompany[];
	projectClientsFacturacion: ProjectClientsFacturacion;
	designAdmin: DesignAdmin;
	designMobile: DesignMobile;
}

export interface DesignMobile {
	secuColor: string;
	color: string;
	urlLogoLogin: string;
	urlLogoSplash: string;
	urlLogoHome: string;
	loginName: string;
	splashName: string;
	homeName: string;
}

export interface DesignAdmin {
	color: string;
	urlLogo: string;
	logoName: string;
	background: string;
	backgroundName: string;
}

interface ProjectClientsFacturacion {
	idCompaniesTaxData: number;
	idCompany: number;
	useCfdi: string;
	formPayment: string;
	methodPayment: string;
	receiptType: string;
	discount: string;
	currency: string;
	exchangeRate: string;
	paymentConditions: string;
	totalArticles: string;
	description: string;
	amount: string;
	keyunit: string;
	keyProdServ: string;
	unit: string;
	tax: string;
	typeFactor: string;
	rfc: string;
	razonSocial: string;
	taxRegime: string;
	zipcode: string;
	fee: number;
	serie: string;
}

interface ProjectContactsCompany {
	idContactCompany: number;
	idBusiness: number;
	name: string;
	firstName: string;
	secondName: string;
	email: string;
	phoneNumber: string;
}

interface ProjectBusinessUnit {
	idBusinesunit: number;
	idBusiness: number;
	name: string;
	authorization: string;
	status: number;
}

interface ProjectCompany {
	idBusiness: number;
	name: string;
	logo: string;
	external: string;
	status: number;
	licenses: number;
	idOrganization: number;
	sms: string;
	userPool: string;
	color: string;
	cognitoClient: string;
	activationKey: string;
	idRole: number;
	tradeName: string;
	idClient: number;
	acronym: string;
	idLada: number;
}

export interface ConfigurationProject {
	type: number;
	info: ProjectInfo;
	admin: Partial<DesignAdmin>;
	mobile: Partial<DesignMobile>;
	step: StepProject;
	percentage: number;
}

export enum StepProject {
	INFO,
	ADMIN,
	APP,
}

export enum TypeFileImage {
	LOGO,
	BACKGROUND,
	LOGIN,
	SPLASH,
	HOME,
}

export interface ProjectConfiguration {
	projectId: number;
	projectName: string;
	clientId: number;
	logo: string;
	allClient: boolean;
	color: string;
	backgroundUrl: string;
	logoProject: string;
}
