import { createAction, props } from "@ngrx/store";
import { ButtonDesign, QRDesign, TypeIcon } from "src/app/integration/models/integration.model";

export const setFormQR = createAction('[INTEGRATION] Set form', props<{data: any}>());
// export const updateForm = createAction('[INTEGRATION] Actualizar form', props<{key: string, value: string}>());
export const setStepQr = createAction('[INTEGRATION] Set step QR', props<{step: number}>())
export const updateButton = createAction('[INTEGRATION] Actualizar Boton', props<{key: keyof ButtonDesign, value: string | boolean | TypeIcon}>());
export const updatePartialButton = createAction('[INTEGRATION] Actualizar Boton', props<{data: Partial<ButtonDesign>}>());
export const updatePartialQr = createAction('[INTEGRATION] Actualizar Qr', props<{data: Partial<QRDesign>}>());
export const clearQr = createAction('[INTEGRATION] Limpiar Qr');
