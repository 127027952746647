const BASE_API = 'https://ja4j8mqyia.execute-api.us-east-1.amazonaws.com/dev';

const BASE_TRADE_API =
	'https://1k59zncqwk.execute-api.us-east-1.amazonaws.com/v1';
const BASE_API_TRADER =
	'https://1xbgxunh2a.execute-api.us-east-1.amazonaws.com/dev';
const BASE_TRADE_API_CATALOG =
	'https://kawoyt8rg0.execute-api.us-east-1.amazonaws.com/dev';

/******* NEW API'S ***************/
const API_LOGIN = 'https://d4qo4rsz5l.execute-api.us-east-1.amazonaws.com/dev';

export const environment = {
	production: false,
	ENV: 'dev',
	ACCESS: {
		key: 'AKIAQTIVNMC5BYOKKAGV',
		seed: 'aBmy3Wpe+SoGMBSr2KOW9UAAIS24+aIkeM3E66F9',
	},
	BUCKETS: {
		report_files: 'dev.traderinc.reports.files',
		survey_files: 'dev.traderinc.survey.files',
		structure_files: 'dev.traderinc.structure.files',
		files_validator: 'dev.traderinc.files.validator',
		tasks: 'dev.traderinc.tasks',
		structure: 'dev.traderinc.structure',
		resource: 'dev.traderinc.resource',
		files: 'dev.traderinc.files',
		notas: 'dev.trader.notas',
	},
	firebase: {
		// type: 'service_account',
		projectId: 'trader-dev-324821',
		apiKey: '787d61e8f87e4643e08ea04259a76baa141b71eb',
		storageBucket: 'gs://trader-dev-324821.appspot.com/',
		databaseURL: 'https://trader-dev-324821-default-rtdb.firebaseio.com',
		// private_key: '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC0/ZbYIxt60lIO\nGezs7Oxi8HyGhW0bpUYI6iZzxrbYRWq8/3YSSuI3Jhv5XSULy9+Vy6qYhpZZe016\n2COkny2jQdGcRJqrcFcIoiVc7KyWtgKl6n8y73UnKbXtfzRGaWOGk7IoaHfw3/jp\nbpYQHG0/B8mXMq+f3lpLtDqJEWZ9SGfwDv4YseQGI9P/Y3mXRz1pIV5KqsdJ1Djt\nOoihW0saPMwu/QcNyOgrxnf4ykbtQ0oY75QGSwVr+42xuS7VM93EoY5pJ5hJe82G\nI+mddScknKz9XCn8qWgUm2dKBZeSi+mTPbiz99TlXJgg92aAdV8ieThXVOWYdYRx\nrrVtg9BHAgMBAAECggEACQ4O3lPGhWFaw/3XQds9afQu84P4lRBMIRhyqAqVdA7o\nOJOg7DVdSY9y76NE8TW+XLFkOl4XzSfc4a9I/Gyos3XdiMkd4kN49z01fu3dJjhU\nImNJZ3QqrLTqty3mbaD9xVCqWL8xRDA2zpolXW6VDWw6t7SZEl4YSMu38GRm3kM/\nHvkf9n5pKOFFLRUT0+ribwlGSereheR1JxjcB8/cWXGeE3bT2SSkvqBitPUeNdgV\n236gPOAu26KPeJA0iUUZqjg4RAGdJSAooXoqCv7y2yWXZaPKNZ3A4NWlZTPAOBWL\n6yXeOA5Us75IPpq1pmLNxToYKHEHcz0dXgxCbFPKsQKBgQDk3Za3Uc8boom6hgBp\npSNQQVYaseJBx2KqR9Hdw7VTPbTr55kb3I97vI5Zqv6BVqKo4hc0YUxqkwCbaNbQ\nrEetYLyUWs7p573TnvMaEJm3pg51t+/PPloKXbEXjhaEbwUKPFbLMGmTdtzSpku9\nwMono74wxoC/SHwJjvtLt0HSLQKBgQDKcuxJ7gUx8Utcp9a7q2i7YSgilhnIANIy\npLCJFX9qqICrAhStU3o8jUzQZxtRzXolMp9d5zvWm0g4F4CPc7fF4iOOkp5sdUDa\nU2gAhgCsoxeO7xoanDVv4FFbCTQT7rPQQ14/iEKGGkk+PhILqk17A5D6cCmDJB0J\nNTX7466YwwKBgF3pP0sB2sYwL+vFyK2RAaiQ5tAVZg4vzqCoz8j74Y/CplaWthDk\ntEvF13Ob4ssXza8W9wabVkv0/i7HGj6OMutF4pvNl1ILsa0AcCil3y2oig5r549W\nuoO+ZkiQGVVGaP8GO2cs+mV0RLB7ZGD8lvclDYneKj5Tc/JIJX2wOOYRAoGBAIXq\nJSD2IFub6CTfJOy5AqtIJjtSnLUuzdwQEnJrVQBhe9MiqjjIyke5lAEa4vhmehCW\n/tInve5Egy4PZcv+WKa94pVRqRfHK+H/YAFQoquPqmqzAMpvX+2kLUyRtvAkaq8t\nkO9nm9GHG8HfXyJ2QZBImKMAhIUp40cO+qmnPvvvAoGAB4U4cmwKXKv8d0Xj6g5i\nKZYZaJER6IOTpNFS9FySrXtZGuK5gdKJut3tR2NoRXmDMmioii2ZJ2IobB3lATjO\nWkMp2Na46nflczxKZRKKXfOFk9GJaCG8mkaQPFmmg5EwPigwy7ubMx8dvfPOa//k\nO2BKKLvT8D9wi5B9heNsjxs=\n-----END PRIVATE KEY-----\n',
		// client_email: 'firebase-adminsdk-r64tn@trader-dev-324821.iam.gserviceaccount.com',
		// client_id: '117269479096525304716',
		// auth_uri: 'https://accounts.google.com/o/oauth2/auth',
		// token_uri: 'https://oauth2.googleapis.com/token',
		// auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
		// client_x509_cert_url: 'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-r64tn%40trader-dev-324821.iam.gserviceaccount.com'
	},
	EDITOR_DNS: 'https://survey.editor.trader-dev.net?userId=',
	TERMINOS: 'https://api.mobile.trader.inc/access/termsConditions',
	SECURITY_API: {
		ENCRYPT: BASE_API + '/security/encrypt',
		DECRYPT: BASE_API + '/security/decrypt',
	},
	API: {
		BASE_API: BASE_API,
		TASK: BASE_API + '/task',
		TASK_PROYECT: BASE_API + '/task-project',
		API_MISSIONS: BASE_API,
		ACTIONS: BASE_API + '/actions',
		PLANNING: BASE_TRADE_API + '/planning',
		COMPANY_TRADE: BASE_TRADE_API + '/company',
		BULKUPLOADPLANING: BASE_TRADE_API + '/bulkupload',
		ASSIGMENT: BASE_TRADE_API + '/assignment',
		RESOURCE_STORE_FORMAT: BASE_TRADE_API + '/resourcestoreformat',
		RESOURCE_ACTIVITY: BASE_TRADE_API + '/resourceactivity',
		PRODUCT: BASE_TRADE_API,
		CATALOG: BASE_TRADE_API_CATALOG,
		SECTIONS: BASE_API + '/communiques/sections',
		BILLING: BASE_API_TRADER + '/facturas',
		USERS: BASE_API + '/users',
		USER: API_LOGIN + '/user',
		CUSTOM_ADMIN: BASE_API + '/custom-new-admin',
		EDIT_USER: BASE_API + '/edituser',
		LOGIN: BASE_API + '/user-admin',
		NEW_LOGIN: BASE_API + '/login-admin-new',
		LOGIN_CONFIG: BASE_API + '/login-config',
		COMMUNIQUE: BASE_API + '/communiques',
		USERS_VALIDATION: BASE_API + '/bills/userValidation',
		PAYMENTS: BASE_API + '/payments',
		PAYMENTS_FILES: BASE_API + '/invoice',
		PAYMENTS_ORDER: BASE_API + '/paymentorders',
		ORDER_PAYMENT: BASE_API + '/order-payment',
		DETAILS_PAYMENT: BASE_API + '/details',
		PAYMENTS_USER: BASE_API + '/payments-user',
		PAYMENTS_DETAILS: BASE_API + '/totalpayments',
		PAYMENTS_AUTHORIZATION: BASE_API + '/payment-authorization',
		PAYMENTS_COMPANIES: BASE_API + '/payment-companies',
		CONCILIACION: BASE_API + '/conciliation',
		BANK: API_LOGIN + '/enrollment',
		CHECKIN: BASE_API + '/check-report',
		SALES_ADVANCE: BASE_API + '/sales-advance',
		DISPERSE_RECONCILE: BASE_API + '/disperse-conciliate',
		DISPERSE: BASE_API + '/disperse-reconcile',
		REPORTS: {
			CU_REPORT: BASE_API + '/cu-report',
			REPORT: BASE_API + '/report',
			MISSIONS: BASE_API + '/mission-report',
			COMMUNIQUES: BASE_API + '/communiques-report',
			COMMUNIQUE_EXCEL: BASE_API + '/report-communique',
			DIGITAL_STAMPS: BASE_API + '/report-digital-stamps',
			DIGITAL_STAMPS_FILTER: BASE_API + '/digital-stamps',
			BILLS_PAYMENTS: BASE_API + '/invoices-payment',
			CHECK_IN: BASE_API + '/check',
			ACTIONS_REPORT: BASE_API + '/actions-info',
			REPORT_COURSE: BASE_API + '/report-course',
			EMAIL_REPORT: BASE_API + '/get-reports',
			NOTIFICATION: BASE_API + '/notification-report',
			EPOS_COUNTERS: BASE_API + '/epos-counters',
		},
		SETTINGS: {
			CHAT_MANAGEMENT: BASE_API + '/chat/admin',
		},
		COMPLEMENT_PAYMENT: BASE_API + '/complement-payment',
		DISPERSE_OPTIONS: BASE_API + '/disperse-options',
		RETENTION: BASE_API + '/retention',
		RETENTION_MASSIVE: BASE_API + '/retention-massive',
		TASK_MISSION: BASE_API + '/task-mission',
		INVOISE_MASSIVE: BASE_API + '/invoices-massive',
		COMPLEMENT_MASSIVE: BASE_API + '/complement-massive',
		ENROLLMENT: BASE_API + '/enrollment-admin',
		RETENTION_REPORT: BASE_API + '/retention-report',
		TRADER_REPORT: BASE_API + '/trader-report',
		SALES_ADVANCE_EDITOR: BASE_API + '/sales-advance-editor',
		SALES_ADVANCE_TEMPLATE: BASE_API + '/sales-info',
		SALES_ADVANCE_REPORT: BASE_API + '/sales-advance-filter',
		RETRY_BILLING: BASE_API + '/retry-billing',
		ACTIONS_REPORT: BASE_API + '/admin-actions-report',
		CHECKIN_EPOS: BASE_API + '/check-inn-epos-module',
		ACTIONS_EXPORT: BASE_API + '/missions-info',
		CHECKINOUT: BASE_API + '/checkin-checkout',
		MODULE: BASE_API + '/module',
		COURSE: {
			COURSE: BASE_API + '',
			CONFIGURATION: BASE_API + '/cu-course-settings',
			LESSON: BASE_API + '/cu-course-lesson',
			EVALUATION: BASE_API + '/cu-test',
		},
		NOTIFICATION: BASE_API + '/notifications-front',
		SURVEY: BASE_API + '/survey-new',
		INTEGRATION: {
			EPOS: BASE_API + '/gestion-epos',
			SALUD: BASE_API + '/mas-salud',
			QR: BASE_API + '/integration/qr',
		},
		PLUS: BASE_API + '/users-plus',
	},
};
