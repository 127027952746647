import { Directive, ElementRef, EmbeddedViewRef, HostListener, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { DirectionTooltip } from '../models/tooltip.model';

@Directive({
  selector: '[appTooltipV2]'
})
export class TooltipV2Directive {

  @Input() tooltip: TemplateRef<any>;
  @Input() position = DirectionTooltip.TOPLEFT;
  @Input() classTooltip: string = '';
  private componentRef: EmbeddedViewRef<any>;

  constructor(
      private elementRef: ElementRef,
      private vcr: ViewContainerRef,
    ) { }
  
    @HostListener('mouseenter') showTooltip () {
      this.componentRef = this.vcr.createEmbeddedView(this.tooltip);
      this.componentRef.detectChanges();
      const domElem = this.componentRef.rootNodes[0] as HTMLDivElement;
      const { height } = domElem.getBoundingClientRect();
      const { left, bottom, top, width } = this.elementRef.nativeElement.getBoundingClientRect();
      if (this.classTooltip) {
        domElem.classList.add(this.classTooltip)
      }
      if (this.position === DirectionTooltip.BOTTOMLEFT) {
        domElem.style.left = (left + 3) + 'px';
        domElem.style.top = (bottom - 8) + 'px';
      }
      if (this.position === DirectionTooltip.TOPLEFT) {
        domElem.style.left = (left + (width / 2)) + 'px';
        domElem.style.top = (top - (height / 2) + 10) + 'px';
      }
      document.body.appendChild(domElem);
    }
  
    @HostListener('mouseleave') onMouseLeave(): void {
      this.componentRef.destroy();
    }

}
