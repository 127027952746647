import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { Business, DesignProject } from 'src/app/auth/models/login.model';
import { Client, Project } from '../../models/header.model';
import {
	setClient,
	setProject,
	unsetProject,
} from '../../../store/project/project.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import {
	isSuperAdmin,
	selectclientList,
	selectDesignProjects,
} from 'src/app/auth/auth.selectors';
import { OverlayPanel } from 'primeng/overlaypanel';
import { MatButton } from '@angular/material/button';
import { selectProjectConfiguration } from 'src/app/store/project/project.selectors';
import { ProjectData } from 'src/app/project-management/models/project.model';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	clientsBusiness: Business[] = [];
	clients: Client[] = [];
	project: Project[] = [];
	clientSelected: number | null = null;
	nameClient: string = '';
	projectSelected: number | null = null;
	nameProject: string = '';
	logo: string = '';
	destroyed$ = new Subject();
	selectAllClient = false;
	designsProjects: DesignProject[] = [];
	color = '';
	type: 'c' | 'p' = 'c';
	isSuperAdmin: boolean = false;

	@ViewChild('client', { static: true }) clientDropdown: OverlayPanel;
	@ViewChild('clientButton') clientButton: MatButton;

	@ViewChild('projectPanel', { static: true }) projectDropdown: OverlayPanel;
	@ViewChild('projectButton') projectButton: MatButton;

	constructor(private store: Store<AppState>, private router: Router) {}

	openPanel(event: Event, type: 'c' | 'p') {
		this.type = type;
		const panel = type === 'c' ? this.clientDropdown : this.projectDropdown;
		const button = type === 'c' ? this.clientButton : this.projectButton;
		panel.toggle(event);
		setTimeout(() => {
			const panel = document.querySelector(
				type === 'c' ? '.panel-client' : '.panel-project'
			) as HTMLElement;
			const { width } =
				button._elementRef.nativeElement.getBoundingClientRect();
			let left = 0;
			if (width > 300) {
				left = (width - 300) / 2;
				console.log(panel.style.left, left);
				panel.style.left = +panel.style.left.split('px')[0] + left + 'px';
			}
			panel.style.backgroundColor = this.color || 'var(--color)';
			panel.style.width = width + 'px';
			panel.style.display = 'block';
		}, 100);
	}

	ngOnInit(): void {
		this.store
			.select(selectDesignProjects)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((designs) => {
				if (!designs.length) return;
				this.designsProjects = designs;
				if (this.projectSelected) {
					const designProject = this.designsProjects.find(
						(d) => d.idBusiness === this.projectSelected
					);
					const projectInfo = {
						projectId: this.projectSelected,
						projectName: this.nameProject,
						color: designProject?.color ?? '',
						backgroundUrl: designProject?.urlLogoBackground ?? '',
						logoProject: designProject?.urlLogo ?? '',
					};
					this.store.dispatch(
						setProject({
							project: projectInfo,
						})
					);
					this.saveProjectLocal(projectInfo);
				}
			});
		this.store
			.select(isSuperAdmin)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((s) => (this.isSuperAdmin = s));
		this.store
			.select(selectclientList)
			.pipe(takeUntil(this.destroyed$))
			.subscribe((clients) => {
				if (clients) {
					this.clientsBusiness = [...clients];
					if (this.isSuperAdmin) {
						this.clientsBusiness.unshift({
							id: 0,
							traderName: 'Todos',
							name: 'Todos',
							logo: '',
							businessList: [],
						});
					}
					this.clients = this.clientsBusiness.map((item) => {
						return {
							id: item.id,
							name: item.traderName,
							select: this.clientSelected === item.id,
							url: item.logo,
						};
					});
					if (this.clientSelected) {
						const idx = this.clientsBusiness.findIndex(
							(item) => item.id === this.clientSelected
						);
						this.project = this.clientsBusiness[idx].businessList.map(
							(item) => {
								return {
									id: item.id,
									name: item.name,
									select: this.projectSelected === item.id,
								};
							}
						);
					}
					if (this.clients.length === 1) {
						this.selectClient(true, this.clients[0], true);
					}
				}
			});
		this.store
			.select(selectProjectConfiguration)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(({ projectId, clientId, allClient, color }) => {
				console.log('selectProjectConfiguration');
				this.color = color;
				const panel = document.querySelector(
					this.type === 'c' ? '.panel-client' : '.panel-project'
				) as HTMLElement;
				if (panel) {
					panel.style.backgroundColor = this.color || 'var(--color)';
				}
				if (
					projectId > -1 &&
					clientId > -1 &&
					this.clientsBusiness.length > 0
				) {
					this.clientSelected = clientId;
					this.projectSelected = projectId;
					const idx = this.clientsBusiness.findIndex(
						(item) => item.id === clientId
					);
					if (idx === -1) return;
					// this.nameClient = this.clientsBusiness[idx].name;
					const clientSelected = this.clients.find(
						(item) => item.id === clientId
					);
					if (clientSelected) {
						this.nameClient = clientSelected.name;
						clientSelected.select = true;
					}
					this.project = this.clientsBusiness[idx].businessList.map((item) => {
						if (item.id === this.projectSelected) {
							this.nameProject = item.name;
						}
						return {
							id: item.id,
							name: item.name,
							select: item.id === this.projectSelected,
						};
					});
				} else if (allClient) {
					this.selectAllClient = allClient;
					this.clientSelected = 0;
					this.nameClient = 'Todos';
					const clientSelected = this.clients.find((item) => item.id === 0);
					if (clientSelected) {
						this.nameClient = clientSelected.name;
						clientSelected.select = true;
					}
				} else if (projectId === -1 && clientId === -1) {
					this.clearAll(false);
				}
			});
	}

	ngOnDestroy(): void {
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	selectClient(check: boolean, client: Client, projectAuto = false) {
		if (check) {
			this.clientSelected = client.id;
			this.nameClient = client.name;
			const idx = this.clientsBusiness.findIndex(
				(item) => item.id === client.id
			);
			this.project = this.clientsBusiness[idx].businessList.map((item) => {
				return {
					id: item.id,
					name: item.name,
					select: false,
				};
			});
			this.logo = client.url;
			this.store.dispatch(
				setClient({ clientId: this.clientSelected, logo: client.url ?? '' })
			);
			if (client.id === 0) {
				this.saveProjectLocal({});
			}
			if (client.id !== 0) {
				this.selectAllClient = false;
			}
			if (this.project.length === 1 && projectAuto) {
				this.selectProject(true, this.project[0]);
			}
		} else {
			this.clearAll();
		}
		if (!projectAuto && !this.selectAllClient) {
			localStorage.removeItem('project');
			this.navigateHome();
		}
	}

	selectProject(check: boolean, project: Project) {
		if (check) {
			this.projectSelected = project.id;
			this.nameProject = project.name;
			const designProject = this.designsProjects.find(
				(d) => d.idBusiness === project.id
			);
			const projectInfo = {
				projectId: this.projectSelected,
				projectName: this.nameProject,
				color: designProject?.color ?? '',
				backgroundUrl: designProject?.urlLogoBackground ?? '',
				logoProject: designProject?.urlLogo ?? '',
			};
			this.store.dispatch(
				setProject({
					project: projectInfo,
				})
			);
			this.saveProjectLocal(projectInfo);
		} else {
			this.projectSelected = null;
			this.nameProject = '';
			this.store.dispatch(
				setProject({
					project: {
						projectId: -1,
						projectName: '',
						color: '',
						backgroundUrl: '',
						logoProject: '',
					},
				})
			);
			localStorage.removeItem('project');
			this.navigateHome();
		}
	}

	saveProjectLocal(project: Partial<ProjectData>) {
		const data = {
			projectId: this.projectSelected || -1,
			clientId: this.clientSelected || 0,
			projectName: this.nameProject,
			logo: this.logo,
			allClient: this.selectAllClient,
			...project,
		};
		localStorage.setItem('project', btoa(JSON.stringify(data)));
	}

	navigateHome() {
		const url = this.router.url.split('/')[-1];
		if (url !== 'home') {
			this.router.navigate(['/admin/home']);
		}
	}

	clearAll(store = true) {
		this.clientSelected = null;
		this.project = [];
		this.projectSelected = null;
		this.nameProject = '';
		this.nameClient = '';
		this.selectAllClient = false;
		if (store) {
			this.store.dispatch(unsetProject());
		}
		console.log('clear');
	}
}
