import { createReducer, on } from "@ngrx/store";
import * as action from "./module.actions";
import { Module } from "src/app/settings/models/module.model";
// import {NewUser, OperationTypes} from "./models/login.model";

export interface ModuleState {
	modules: Module[];
	modulesForEdit: Module[];
	modulesQr: Module[];
	idModuleDetail: number;
}

export const initialState: ModuleState = {
	modules: [],
	modulesForEdit: [],
	modulesQr: [],
	idModuleDetail: -1,
}

export const moduleReducer = createReducer(
	initialState,
	on(action.setModules, (state, {modules}) => ({ ...state, modules})),
	on(action.clearModuleForEdit, (state) => ({ ...state, modulesForEdit: [], modulesQr: []})),
	on(action.setModuleForEdit, (state, {moduleForEdit}) => ({...state, modulesForEdit: [moduleForEdit, ...state.modulesForEdit.filter(m => m.idModule !== moduleForEdit.idModule)]})),
	on(action.setModuleForEditQR, (state, {moduleForEdit}) => ({...state, modulesQr: [moduleForEdit, ...state.modulesQr.filter(m => m.idModule !== moduleForEdit.idModule)]})),
	on(action.setModuleDetail, (state, {id}) => ({ ...state, idModuleDetail: id})),
)
