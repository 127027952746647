export const myRoutes: Routes = {
	user: [
		{ route: '/admin/home' },
		{ route: '/admin/editor/list' },
		{ route: '/admin/task/programing' },
		{ route: '/admin/mission/missions' },
		{ route: '/admin/mission/missionsIns' },
		{ route: '/admin/mission/missionsActions' },
		{ route: '/admin/mission/missionsUseres' },
		{ route: '/admin/mission/missionsAssignment' },
		{ route: '/admin/reports/check-in' },
		{ route: '/admin/users/list' },
		{ route: '/admin/users/new' },
		{ route: '/admin/billing/list' },
		{ route: '/admin/payment/payment-management' },
		{ route: '/admin/payment/paymentIns' },
		{ route: '/admin/payment/paymentStatus' },
		{ route: '/admin/payment/payment-authorization' },
		{ route: '/admin/payment/payment-detail' },
	],
	admin: [
		{ route: '/admin/home' },
		{ route: '/admin/home/dashboard' },
		{ route: '/admin/editor/list' },
		{ route: '/admin/editor/list-v2' },
		{ route: '/admin/editor/create' },
		{ route: '/admin/task/programing' },
		{ route: '/admin/communique/list' },
		{ route: '/admin/communique/programing' },
		{ route: '/admin/sales/upload-file' },
		{ route: '/admin/mission/list' },
		{ route: '/admin/mission/new-mission' },
		{ route: '/admin/mission/missions-actions' },
		{ route: '/admin/mission/missions-useres' },
		{ route: '/admin/mission/missions-assignment' },
		{ route: '/admin/clients/list' },
		{ route: '/admin/clients/new-client' },
		{ route: '/admin/users/list' },
		{ route: '/admin/users/new' },
		{ route: '/admin/layout/upload' },
		{ route: '/admin/branch-office/upload' },
		{ route: '/admin/billing/pay-specials' },
		{ route: '/admin/billing/payments' },
		{ route: '/admin/sections/list' },
		{ route: '/admin/payment/list' },
		{ route: '/admin/payment/pay-order' },
		{ route: '/admin/payment/payment-management' },
		{ route: '/admin/payment/paymentIns' },
		{ route: '/admin/payment/paymentStatus' },
		{ route: '/admin/payment/payment-authorization' },
		{ route: '/admin/payment/payment-detail' },
		{ route: '/admin/disperse-reconcile/list' },
		{ route: '/admin/disperse-reconcile/payment-detail' },
		{ route: '/admin/reports/check-in' },
		{ route: '/admin/reports/missions' },
		{ route: '/admin/reports/actions' },
		{ route: '/admin/reports/course' },
		{ route: '/admin/reports/communiques' },
		{ route: '/admin/reports/digital-stamps' },
		{ route: '/admin/reports/bills-payments' },
		{ route: '/admin/billing/list' },
		{ route: '/admin/retention/list' },
		{ route: '/admin/reports/retentions' },
		{ route: '/admin/reports/trader-info' },
		{ route: '/admin/sales/list' },
		{ route: '/admin/sales/editor' },
		{ route: '/admin/sales/details' },
		{ route: '/admin/reports/sales-advance' },
		{ route: '/admin/reports/actions' },
		{ route: '/admin/check-in/list' },
		{ route: '/admin/settings/module-management' },
		{ route: '/admin/settings/chat-management' },
		{ route: '/admin/course/list' },
		{ route: '/admin/course/course-config' },
		{ route: '/admin/course/evaluation' },
		{ route: '/admin/course/lesson' },
		{ route: '/admin/project-management/project' },
		{ route: '/admin/project-management/profiles' },
		{ route: '/admin/project-management/roles' },
		{ route: '/admin/project-management/create-role' },
		{ route: '/admin/project-management/edit-role' },
		{ route: '/admin/notification/list' },
		{ route: '/admin/notification/create' },
		{ route: '/admin/notification/reasign' },
		{ route: '/admin/reports/notification' },
		{ route: '/admin/integration/list' },
		{ route: '/admin/gallery' },
	],
	adminNotProyect: [{ route: '/admin/home' }],
	adminCustom: [
		{ route: '/admin/home' },
		{ route: '/admin/communique/list' },
		{ route: '/admin/communique/programing' },
		{ route: '/admin/users/list' },
		{ route: '/admin/sections/list' },
	],
};

interface Routes {
	user: Route[];
	admin: Route[];
	adminCustom: Route[];
	adminNotProyect: Route[];
}

export interface Route {
	route: string;
}
