import { createReducer, on } from "@ngrx/store";
import { ButtonDesign, IntegrationForm, QRDesign } from "src/app/integration/models/integration.model";
import { clearQr, setFormQR, setStepQr, updateButton, updatePartialButton, updatePartialQr } from "./integration.actions";

export interface IntegrationQRState {
	form: IntegrationForm;
  step: number;
  buttonDesign: ButtonDesign;
  QRDesign: QRDesign;
}

export const initialState: IntegrationQRState = {
	form: {
    name: '',
    url: '',
    plattformSwitch: false,
    projectSwitch: false,
    troderSwitch: false,
    plattformName: '', 
    plattformValue: '',
    projectName: '',
    troderName: '',
    valid: false
  },
  step: 1,
  buttonDesign: {
    title: '',
    subtitle: '',
    share: '',
    color: '',
    typeIcon: 'icon',
    icon: '',
    showTooltip: false,
    textTooltip: '',
    valid: false,
  },
  QRDesign: {
    description: '',
    color: '',
    showBenefits: false,
    textBenefits: '',
    valid: false,
  }
}

export const integrationReducer = createReducer(
  initialState,
  on(clearQr, (state) => ({...initialState})),
  on(setFormQR, (state, {data}) => ({...state, form: data})),
  on(setStepQr, (state, {step}) => ({...state, step})),
  // on(updateForm, (state, {key, value}) => ({...state, form: { ...state.form, [key]: value }})),
  on(updateButton, (state, {key, value}) => ({...state, buttonDesign: { ...state.buttonDesign, [key]: value }})),
  on(updatePartialButton, (state, {data}) => ({...state, buttonDesign: { ...state.buttonDesign, ...data }})),
  on(updatePartialQr, (state, {data}) => ({...state, QRDesign: { ...state.QRDesign, ...data }})),
);