import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContainerComponent } from './home/pages/container/container.component';
import { UserProfileGuard } from './guards/user-profile.guard';
import { PublicGuard } from './guards/public.guard';

const routes: Routes = [
	{
		path: 'auth',
		canActivate: [PublicGuard],
		loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
	},
	{
		path: 'admin',
		component: ContainerComponent,
		canActivate: [UserProfileGuard],
		canActivateChild: [UserProfileGuard],
		children: [
			{
				path: 'home',
				loadChildren: () =>
					import('./home/home.module').then((m) => m.HomeModule),
			},
			{
				path: 'project-management',
				loadChildren: () =>
					import('./project-management/project-management.module').then(
						(m) => m.ProjectManagementModule
					),
			},
			{
				path: 'editor',
				loadChildren: () =>
					import('./editor/editor.module').then((m) => m.EditorModule),
				canActivate: [UserProfileGuard],
			},
			{
				path: 'task',
				loadChildren: () =>
					import('./task/task.module').then((m) => m.TaskModule),
			},
			{
				path: 'communique',
				loadChildren: () =>
					import('./communique/communique.module').then(
						(m) => m.CommuniqueModule
					),
			},
			{
				path: 'sales',
				loadChildren: () =>
					import('./sales-v3/sales-v3.module').then((s) => s.SalesV3Module),
			},
			{
				path: 'clients',
				loadChildren: () =>
					import('./clients/clients.module').then((m) => m.ClientsModule),
			},
			{
				path: 'mission',
				loadChildren: () =>
					import('./missions-v2/missions-v2.module').then(
						(m) => m.MissionsV2Module
					),
			},
			{
				path: 'payment',
				loadChildren: () =>
					import('./payments/payments.module').then((m) => m.PaymentsModule),
			},
			{
				path: 'disperse-reconcile',
				loadChildren: () =>
					import('./disperse-reconcile/disperse-reconcile.module').then(
						(m) => m.DisperseReconcileModule
					),
			},
			{
				path: 'reports',
				loadChildren: () =>
					import('./reports/reports.module').then((m) => m.ReportsModule),
			},
			{
				path: 'gallery',
				loadChildren: () =>
					import('./gallery/gallery.module').then((m) => m.GalleryModule),
			},
			{
				path: 'users',
				loadChildren: () =>
					import('./users/users.module').then((m) => m.UsersModule),
			},
			{
				path: 'sections',
				loadChildren: () =>
					import('./sections/sections.module').then((m) => m.SectionsModule),
			},
			{
				path: 'retention',
				loadChildren: () =>
					import('./retention/retention.module').then((m) => m.RetentionModule),
			},
			{
				path: 'check-in',
				loadChildren: () =>
					import('./check-in/check-in.module').then((m) => m.CheckInModule),
			},
			{
				path: 'settings',
				loadChildren: () =>
					import('./settings/settings.module').then((m) => m.SettingsModule),
			},
			{
				path: 'course',
				loadChildren: () =>
					import('./course/course.module').then((m) => m.CourseModule),
			},
			{
				path: 'notification',
				loadChildren: () =>
					import('./notification/notification.module').then(
						(m) => m.NotificationModule
					),
			},
			{
				path: 'integration',
				loadChildren: () =>
					import('./integration/integration.module').then(
						(m) => m.IntegrationModule
					),
			},
			{
				path: 'plus',
				loadChildren: () =>
					import('./users-plus/users-plus.module').then((m) => m.UsersPlusModule)
			},
			{ path: '**', redirectTo: 'home', pathMatch: 'full' },
		],
	},
	{ path: '**', redirectTo: 'auth', pathMatch: 'full' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
