import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '../shared/models/response';
import * as CryptoJS from 'crypto-js';

@Injectable({
	providedIn: 'root',
})
export class SecurityService {
	private readonly API = environment.SECURITY_API;
	private secretKey = 'Tr4d3ncryPtEd.K3Y.Tr4d3ncryPtEd+';

	useNew: boolean = true;

	constructor(private http: HttpClient) {}

	encrypt(data: any) {
		if (this.useNew) {
			return this.newEncrypt(data);
		}
		return new Promise((resolve, reject) => {
			this.http.post(`${this.API.ENCRYPT}`, data).subscribe(
				(response: any) => {
					resolve(response);
				},
				(err: any) => reject(err)
			);
		});
	}

	encrypt2(data: any) {
		return this.http.post(`${this.API.ENCRYPT}`, data, {
			headers: { skip: 'true' },
		});
	}

	decrypt(data: any): Promise<Response> {
		if (this.useNew) {
			return this.newDecrypt(data);
		}
		return new Promise((resolve, reject) => {
			this.http.post(`${this.API.DECRYPT}`, data).subscribe(
				(response: any) => {
					resolve(response);
				},
				(err: any) => reject(err)
			);
		});
	}

	decrypt2(data: any) {
		return this.http.post(`${this.API.DECRYPT}`, data, {
			headers: { skip: 'true' },
		});
	}

	healtCheck(url: string) {
		return this.http.post(url, {}, { headers: { skip: 'true' } });
	}

	newEncrypt(data: any) {
		const text = typeof data === 'string' ? data : JSON.stringify(data.data);
		try {
			const key = CryptoJS.enc.Utf8.parse(this.secretKey);
			const iv = CryptoJS.lib.WordArray.random(16);
			const encrypted = CryptoJS.AES.encrypt(text, key, {
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			});
			const ivCipherText = iv.concat(encrypted.ciphertext);
			return { body: { data: CryptoJS.enc.Base64.stringify(ivCipherText) } };
		} catch (error: any) {
			return error.message;
		}
	}

	newDecrypt(encryptedText: any) {
		try {
			const encryptedString = encryptedText.body?.data || encryptedText.data;
			const encryptedData = CryptoJS.enc.Base64.parse(encryptedString);
			const iv = CryptoJS.lib.WordArray.create(encryptedData.words.slice(0, 4));
			const encryptedBytes = CryptoJS.lib.WordArray.create(
				encryptedData.words.slice(4)
			);
			const key = CryptoJS.enc.Utf8.parse(this.secretKey);
			const cipherParams = CryptoJS.lib.CipherParams.create({
				ciphertext: encryptedBytes,
			});
			const bytes = CryptoJS.AES.decrypt(cipherParams, key, {
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			});
			return { body: { data: JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) } };
		} catch (error: any) {
			return error.message;
		}
	}
}
